<template>
    <FormWrapper :is-loading="isLoading">
        <PageTitle title="Document Type" slot="title" link="/helpContent/Document Type Detail" />
        <ValidationObserver ref="validator">
            <FormRow>
                <div class="col-sm-2">
                    <FormSelect label="Document Class" rules="required" :items="documentClassList" item-name="DocumentClass" item-value="id" :onChange="getCategoryList" v-model="form.documentClass"/>
                </div>
                <div class="col-sm-2">
                    <FormSelect label="Document Category" rules="required" :items="documentCategoryList" item-name="DocumentCategory" item-value="id" v-model="form.documentCategory"/>
                </div>
                <div class="col-sm-3">
                    <InputText label="Name" v-model="form.categoryName" rules="required" />
                </div>
            </FormRow>
        </ValidationObserver>
        <FormRow>
            <div class="col-sm-6" >
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                    <FormButton type="success" @click="sendData()">Save</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
        <Popup title="Success" type="success" :value="showMessage.isVisible"  @close="() => (showMessage.isVisible = false)" :closable="true">
            {{ showMessage.message }}
            <div slot="footer">
                <FormButton @click="()=>{$router.go(-1)}">Ok</FormButton>
            </div>
        </Popup>
    </FormWrapper>
</template>
<script>
    import FormWrapper from "Components/form/FormWrapper";
    import PageTitle from "Components/layout/PageTitle";
    import loadingMixin from "Mixins/loadingMixin";
    import {getDocumentClass, documentCategory, getDocumentTypeList, addUpdateDocumentType} from "../api";
    import {ValidationObserver} from "vee-validate"
    
    export default {
        name: "add",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            PageTitle,
            ValidationObserver,
        },
        data() {
            return {
                showMessage: {
                    isVisible: false,
                    message: "",
                },
                infoMessage: {
                    isVisible: false,
                },
                documentClassList: [],
                documentCategoryList:[],
                form: {
                    documentClass:'',
                    documentCategory:'',
                    categoryName:'',
                },
            };
        },
        computed: {
            userData() {
                return this.$store.state.user.user;
            },
        },
        created() {
          this.getAllList();
        },
        methods: {
            async  getAllList(){
                await getDocumentClass().then((res) => {
                    this.documentClassList = res.data
                });
                this.getDataByFilter();
            },
            getCategoryList(id){
                const data = {
                    documentClassId: id,
                };
                documentCategory(data).then((res) => {
                    this.documentCategoryList = res.data
                });
            },
            getDataByFilter() {
                if(this.$route.params.id){
                    setTimeout(() => {
                        const filters = {};
                        filters.documentTypeId = atob(this.$route.params.id);
                        const data = {
                            filterjson: {
                                filter: [filters]
                            }
                        };
                        getDocumentTypeList(data)
                            .then(this.handleResponse)
                            .catch(this.handleError)
                    },
                    50
                    )
                }
            },
            sendData(){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        const data={
                            documentTypeId: this.$route.params.id?atob(this.$route.params.id):0,
                            documentClass:this.form.documentClass,
                            documentCategory:this.form.documentCategory,
                            categoryName:this.form.categoryName
                        };
                        addUpdateDocumentType(data).then((res)=>{
                            this.form = {};
                            this.showMessage.isVisible=true;
                            this.showMessage.message=res.data[0].message;
                        });
                    }
                })
            },
            handleResponse(response) {
                this.hideLoader();
                this.form.documentClass = response.data[0].DocumentClass;
                this.form.documentCategory = response.data[0].DocumentCategoryId;
                this.form.categoryName = response.data[0].description;
                if(this.form.documentClass){
                    this.getCategoryList(this.form.documentClass);
                }
            }
        },
    };
</script>
<style lang="scss">
    .popupRow {
        padding: 5px 0 15px;
    }
    .pagination {
        margin: 10px 0 0;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        line-height: 26px;
    }
    .paginationText {
        margin: 0 20px;
    }
    
    .paginationButton {
        cursor: pointer;
        width: 26px;
        text-align: center;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
            box-shadow 200ms ease;
    }
    
    .paginationButton:hover {
        background: #57a3f3;
        color: #fff;
    
        &:hover {
            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
                0 4px 15px 0 rgba(0, 0, 0, 0.15);
        }
    }
    
    .filter {
        margin: -10px 0 10px;
    }
    
    .filterItem {
        display: inline-block;
        margin: 0 15px 15px 0;
    }
    
    .filterItemCheckbox {
        flex-basis: auto;
        align-self: flex-start;
        margin-top: 13px;
    }

    body{
        padding-right: 0px !important;
        overflow: auto !important;
    }
</style>    